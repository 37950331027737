/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import LocationImage from "../../images/location.png";
import CallIcon from "../../images/call-icon.svg";
import EmailIcon from "../../images/email-icon.svg";
import LocationIcon from "../../images/location-icon.svg";

const OurOffice = () => {
  return (
    <section className="py-5 my-5 container text-center">
      <h2 className="mb-4">
        Our <span className="border border-primary shadow-primary rounded px-3">Office</span>
      </h2>
      <div className="row align-items-center">
        <div className="col-12 col-lg-6">
          <img src={LocationImage} className="img-fluid" alt=""/>
        </div>
        <div className="col-12 col-lg-6 text-center text-lg-start">
          <h1 className="display-3 mb-4">Lahore, Pakistan</h1>
          <div className="d-flex align-items-start gap-2">
            <img src={LocationIcon} className="mt-1" alt=""/>
            <p className="fs-5">501 SILVERSIDE RD STE 105
WILMINGTON , NEW CASTLE
19809
USA</p>
          </div>
          <div className="d-flex flex-wrap gap-3 gap-lg-5">
            <p className="fs-5 mx-auto mx-lg-0">
              <img src={CallIcon} alt=""/> +44 7404837838
            </p>
            <p className="fs-5 mx-auto mx-lg-0">
              <img src={EmailIcon} alt=""/> admin@playerlitestudio.com
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurOffice;
